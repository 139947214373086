body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
}



label {
  color: black;
  font-weight: bold;

  float: left;
  display: block;
}


div {
  color: #b46b7d;
  font-weight: bold;
  display: block;
  
}

li {
  color: black;
  word-wrap: break-word;
  white-space: normal;
}

h3, h4 {
  margin-bottom: 0;
}

nav {
  border-bottom: 4px solid black;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
}

nav li {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
  
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 16px 32px;
}

nav a:hover {
  background: black;
  color: white;

}

#page-body {
  max-width: 700px;
  margin: auto;
  padding-top: 64px;
}

.article-list-item {
  text-decoration: none;
  color: black;
  padding: 16px 0 0 0;
}

.article-list-item p {
  padding-bottom: 32px;
  border-bottom: 2px solid black;
}

#add-comment-form {
  border-bottom: 2px solid black;
  padding: 16px;
}

#add-comment-form h3 {
  margin-bottom: 16px;
}





#add-comment-form button {
  width: 320px;
}

#upvotes-section button, p {
  display: inline-block;
  margin-right: 16px;
}

.comment {
  border-bottom: 2px solid black;
  padding: 16px;
}


.textarea-table, .input-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  text-align: center;
}


.textarea-table td.fixed-size {
  width: 150px; /* Set a fixed width for the property name cell */
  text-align: center; /* Right-align the property name */
  padding-right: 10px; /* Add space between the name and the textarea */
}

.textarea-table td textarea {
  width: 100%; /* Make the textarea span the entire row width */
  border: 1px solid #ccc; /* Add a border to the textarea (customize as needed) */
  padding: 5px; /* Add padding to the textarea (customize as needed) */
  text-align: center;
}

.input-table td input {
  width: 100%; /* Make the inputs span the entire row width */
  border: 1px solid #ccc; /* Add a border to the inputs (customize as needed) */
  padding: 5px; /* Add padding to the inputs (customize as needed) */
  text-align: center;
}

.input-table td {
  margin-right: 10px; /* Add margin between the field name and input */
}

.input-table td.fixed-size {
  width: 100px; /* Set a fixed width for the field name cell */
  text-align: center; /* Left-align the field name */
  padding-left: 10px; /* Add space between the name and the input */
  padding-right: 10px;
}


.form-container {
  width: 100%;
}

.category-row {
  display: flex;
}

.category-column {
  flex: 1;
  margin-right: 20px; /* Adjust margin as needed to create space between columns */
  display: flex;
  flex-direction: column; 
}




/* Manager validation section styling */
.manager-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Title for Manager Validation */
.manager-section h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Manager Code input and Button side by side */
.manager-section .form-field {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  justify-content: flex-start; /* Align left */
  flex-wrap: nowrap; /* Prevent wrapping */
}

/* Fixed width for Manager Code input */
.manager-section  input {
  width: 250px; /* Fixed width for input */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-shrink: 0; /* Prevent input from shrinking */
}

/* Fixed width for Login button */
.manager-section  button {
  padding: 16px;
  width: 200px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  cursor: pointer;
  flex-shrink: 0; /* Prevent button from shrinking */
}

/* Combo Box with smaller size */
.manager-section select {
  width: 250px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
}

button {
  padding: 16px;
  width: 200px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.manager-input {
  width: 200px; /* Adjust the width of the text field */
  padding: 8px; /* Add padding for better visual appearance */
  font-size: 14px; /* Reduce the font size */
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  
}



.validate-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.form-field {
  margin-bottom: 20px; /* Adjust margin as needed to create space between fields */
  flex-wrap: wrap; 
  display: flex;
  flex-direction: column; 
}

.form-field label {
  display: block;
  margin-bottom: 5px; /* Adjust margin as needed to create space between label and input */
}

.data-table-container {
  margin-top: 20px; /* Adjust margin as needed */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px; /* Adjust margin as needed */
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
}
